<template>
  <article class="contact-us" :class="{ mobile: isMobile }">
    <img
      class="banner"
      src="@/assets/images/Global/contact_us_banner.png"
      alt="Electrify America chargers under a lighted canopy at night"
    />
    <div class="article-body text">
      <div class="container">
        <form id="contact-form" @submit.prevent="validate" ref="form">
          <h1 class="header">Let’s power excellence together</h1>
          <p class="detail">
            The road to your electrified future begins now. Help us get to know you (and your project), and we’ll be in
            touch to discuss customized approaches to tackling your clients’ electrification needs.
          </p>
          <div class="form-wrap" :class="{ mobile: isMobile }">
            <div class="input-field">
              <label ref="firstname" for="first_name">First Name<span class="required">*</span> </label>
              <input
                id="first_name"
                maxlength="39"
                name="first_name"
                v-model="firstName"
                pattern="^[A-Za-z '\-\.]+$"
                title="This field only accepts letters and limited special characters"
                type="text"
                required
              />
            </div>
            <div class="input-field">
              <label ref="lastname" for="last_name"
                >Last Name <span class="require-mark" aria-hidden="true">*</span></label
              >
              <input
                id="last_name"
                maxlength="39"
                name="last_name"
                type="text"
                pattern="^[A-Za-z '\-\.]+$"
                title="This field only accepts letters and limited special characters"
                v-model="lastName"
                required
              />
            </div>
            <div class="input-field">
              <label ref="email" for="email"
                >Business Email <span class="require-mark" aria-hidden="true">*</span></label
              >
              <input
                id="email"
                maxlength="80"
                name="email"
                type="text"
                v-model="email"
                pattern="^[^\\]+@[^\\]+\.[^\\]+$"
                title="Please enter a correct email address"
                required
              />
            </div>
            <div class="input-field">
              <label for="phone">Phone Number <span class="require-mark" aria-hidden="true">*</span></label>
              <input
                id="phone"
                maxlength="10"
                name="phone"
                v-model="phone"
                type="text"
                pattern="^\d+$"
                title="This field only accepts numbers"
                required
              />
            </div>
            <div class="input-field">
              <label ref="companyname" for="company_name"
                >Company Name <span class="require-mark" aria-hidden="true">*</span></label
              >
              <input
                id="company_name"
                maxlength="200"
                name="company_name"
                type="text"
                pattern="^[^\\]+$"
                v-model="company"
                required
              />
            </div>
            <div class="input-field">
              <label for="company_role"
                >Company Role <span class="require-mark" aria-hidden="true">*</span>
                <select id="company_role" name="company_role" v-model="companyRole" required>
                  <option v-for="(item, index) in companyRoleOptions" :value="item" :key="index">{{ item }}</option>
                </select>
              </label>
            </div>

            <div class="input-field">
              <label for="like_to">I am interested in <span class="require-mark" aria-hidden="true">*</span></label>
              <select v-model="interestType" name="like_to" id="like_to" required>
                <option v-for="(item, index) in interestTypeOptions" :value="item" :key="index">{{ item }}</option>
              </select>
            </div>

            <div class="input-field">
              <label for="num_properties"
                >I am interested in adding charging to <span class="require-mark" aria-hidden="true">*</span></label
              >
              <select v-model="numberProperties" name="num_properties" id="num_properties" required>
                <option v-for="(item, index) in numberPropertiesOptions" :value="item" :key="index">{{ item }}</option>
              </select>
            </div>

            <div class="input-field">
              <label for="nevi_interest"
                >I am interested in learning about National Electric Vehicle Incentives (NEVI) Program
                <span class="require-mark" aria-hidden="true">*</span>

                <reference-link style="padding-left: 5px" num="1">
                  The Federal Government created NEVI to drive electric mobility by establishing a nationwide EV
                  charging network. NEVI offers $5B in funding over five years (2022 – 2026) for states to strategically
                  deploy 150+ kilowatt (kW) reliable charging stations. Data as of September 2022. Subject to change
                  based on state award.
                  <a
                    href="https://www.fhwa.dot.gov/bipartisan-infrastructure-law/evs_5year_nevi_funding_by_state.cfm"
                    target="_blank"
                    >https://www.fhwa.dot.gov/bipartisan-infrastructure-law/evs_5year_nevi_funding_by_state.cfm</a
                  >
                </reference-link>
              </label>
              <select v-model="neviInterest" name="nevi_interest" id="nevi_interest" required>
                <option v-for="(item, index) in neviInterestOptions" :value="item" :key="index">{{ item }}</option>
              </select>
            </div>

            <div class="input-field">
              <label for="budget_approved"
                >EV Charging Infrastructure Project Budget has been Approved
                <span class="require-mark" aria-hidden="true">*</span></label
              >
              <select v-model="budgetApproved" name="budget_approved" id="budget_approved" required>
                <option v-for="(item, index) in budgetApprovedOptions" :value="item" :key="index">{{ item }}</option>
              </select>
            </div>

            <div class="input-field">
              <label for="country">Country</label>
              <select v-model="country" name="country" id="country">
                <option v-for="(item, index) in countryOptions" :value="item" :key="index">{{ item }}</option>
              </select>
            </div>

            <div class="input-field">
              <label for="address"
                >Desired Charging Station Address<span class="require-mark" aria-hidden="true">*</span>
                <span class="residential-note"
                  ><strong>Note: </strong> We do not accept residential home locations through this form.</span
                ></label
              >
              <input id="address" v-model="street" name="address" type="text" required />
            </div>

            <div class="input-field">
              <label for="city">City <span class="require-mark" aria-hidden="true">*</span></label>
              <input id="city" v-model="city" name="city" type="text" required />
            </div>

            <div class="input-field">
              <label for="state_code"
                >{{ isUs ? 'State' : 'Province' }} <span class="require-mark" aria-hidden="true">*</span>
                <select id="state_code" name="state_code" :disabled="country === ''" v-model="state" required>
                  <option value="" selected hidden>Select a {{ isUs ? 'State' : 'Province' }}</option>
                  <option v-for="item in statesProvinceList" :value="item.name" :key="item.abbreviation">
                    {{ item.name }}
                  </option>
                </select>
              </label>
            </div>

            <div class="input-field">
              <label for="zipCode"
                >{{ isUs ? 'Zip Code' : 'Postal Code' }}<span class="require-mark" aria-hidden="true">*</span></label
              >
              <input
                id="zipCode"
                maxlength="10"
                name="zipCode"
                v-model="zipCode"
                type="text"
                pattern="^[a-zA-Z0-9\s]+(?:[a-zA-Z0-9\-\s])+(?:[a-zA-Z0-9\s])$"
                title="This field accepts numbers, letters, and some special characters"
                required
              />
            </div>

            <div class="input-field">
              <label for="desired_count"
                >Desired DC Fast Charger Count and Parking Spot Availability
                <span class="require-mark" aria-hidden="true">*</span></label
              >
              <select v-model="desiredChargerCount" name="desired_count" id="desired_count" required>
                <option v-for="(item, index) in desiredChargerCountOptions" :value="item" :key="index">
                  {{ item }}
                </option>
              </select>
            </div>

            <div class="input-field">
              <label for="property_type"
                >Property Type
                <span class="require-mark" aria-hidden="true">*</span>
              </label>
              <select v-model="useCase" name="property_type" id="property_type" required>
                <option v-for="(item, index) in useCaseOptions" :value="item" :key="index">{{ item }}</option>
              </select>
            </div>

            <p :class="missedAdditionalDetails ? 'prompt-for-details' : 'prompt-hidden'" id="prompt-for-details">
              Please select at least one of the following options
            </p>

            <div class="input-field">
              <label for="additional_details" id="additional_details"
                >Additional Location Details <span class="require-mark" aria-hidden="true">*</span></label
              >
              <div v-for="(item, index) in additionalDetailsOptions" :value="item" :key="`additional_details_${index}`">
                <input type="checkbox" :value="item" v-model="additionalDetails" :id="`additonal_details_${item}`" />
                <label :for="`additonal_details_${item}`">{{ item }}</label>
              </div>
            </div>

            <div class="input-field">
              <label for="property_relationship"
                >Relationship to Property <span class="require-mark" aria-hidden="true">*</span></label
              >
              <select v-model="propertyRelationship" name="property_relationship" id="property_relationship" required>
                <option v-for="(item, index) in propertyRelationshipOptions" :value="item" :key="index">
                  {{ item }}
                </option>
              </select>
            </div>

            <div class="input-field">
              <label for="notes"
                >Additional Property Notes <span class="require-mark" aria-hidden="true">*</span></label
              >
              <textarea
                placeholder="Notes"
                v-model="notes"
                maxlength="1000"
                id="notes"
                name="notes"
                required
              ></textarea>
            </div>
            <div class="input-field checkbox">
              <label for="future-comm">
                <input v-model="OptMeinFutureComm" id="future-comm" name="future-comm" type="checkbox" value="true" />
                <p class="form-question">
                  Send me news and marketing offers from Electrify America via telephone calls and text messages at the
                  phone number provided. I understand such calls and texts may be made using an automated dialing system
                  and that my consent to receive such calls and texts is not required as a condition of making a
                  purchase.
                  <span
                    >Click <a href="https://www.electrifyamerica.com/privacy/" class="link">here</a> for Electrify
                    America's Privacy Statement.</span
                  >
                </p>
              </label>
            </div>
            <input ref="submitButton" class="button" name="Sign up" type="submit" value="SUBMIT" />
          </div>
          <p v-show="serverError !== ''" class="server-error">
            Error:
            <br />
            {{ serverError }}
          </p>
        </form>
      </div>
    </div>
  </article>
</template>

<script>
import States from '@/assets/json/us_states.json';
import Provinces from '@/assets/json/ca_provinces.json';
import ReferenceLink from '../../components/ReferenceLink.vue';

export default {
  name: 'contactus',
  metaInfo: {
    title: 'Contact us | Electrify Commercial',
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          name: 'Electrify Commercial',
          url: 'https://www.electrify-commercial.com/',
          sameAs: [
            'https://twitter.com/electrifyam',
            'https://www.facebook.com/ElectrifyAmerica/',
            'https://www.instagram.com/electrifyamerica/?hl=en',
            'https://www.linkedin.com/company/electrifyamerica/about/',
          ],
          logo: 'https://twitter.com/electrifyam/photo',
          parentOrganization: {
            '@type': 'Organization',
            name: 'Electrify Commercial',
            url: 'https://www.electrify-commercial.com/',
          },
          contactPoint: [
            {
              '@type': 'ContactPoint',
              telephone: '+1-833-632-2778',
              contactType: 'customer support',
              contactOption: 'TollFree',
              areaServed: 'US',
            },
          ],
        },
      },
    ],
    meta: [
      {
        name: 'description',
        content: `Let's get to work. Electrify Commercial wants to hear from you. Fill out the form and we’ll get back to you soon.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-commercial.com/contact-us/' }],
    noscript: [
      {
        innerHTML:
          '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KXKDRHT" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
        pbody: true,
      },
    ],
  },
  components: { ReferenceLink },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      company: null,
      companyRole: null,
      companyRoleOptions: ['Intern', 'Associate', 'Manager', 'Sr. Manager', 'Director', 'Executive', 'Founder/Owner'],
      interestType: '',
      interestTypeOptions: [
        'Hosting Charging Stations',
        'Owning a Charging Network',
        'Both Owning and Hosting Charging Stations',
      ],
      numberProperties: '',
      numberPropertiesOptions: ['1-4 Properties', '5-10 Properties', '11-15 Properties', '16+ Properties'],
      neviInterest: '',
      neviInterestOptions: ['Yes', 'No'],
      budgetApproved: '',
      budgetApprovedOptions: ['Yes', 'No'],
      phone: null,
      countryOptions: ['United States', 'Canada'],
      country: 'United States',
      city: '',
      street: '',
      state: '',
      statesProvinceList: States,
      desiredChargerCount: '',
      desiredChargerCountOptions: ['0-7', '8-10', '11-15', '16-20', '21-25', '26+'],
      zipCode: null,
      additionalDetails: [],
      additionalDetailsOptions: [
        'Retail',
        'Food and Beverages',
        'Public Restrooms',
        'Entertainment',
        'Grocery',
        'Fueling Center',
        '24/7 Customer Accessibility',
        'Gated Parking Lot',
        'Customers Pay for Parking',
        'High-traffic location',
        'Highway Proximity',
        'Residential',
        'Rural',
      ],
      useCase: '',
      useCaseOptions: [
        'Retail',
        'Restaurant',
        'Hospitality/Hotel',
        'Coffee Shop',
        'Fast Casual Restaurant',
        'Government',
        'Convenience',
        'Workplace',
        'Mixed Use',
        'Other',
      ],
      propertyRelationship: null,
      propertyRelationshipOptions: ['Own', 'Lease', 'Franchise'],
      notes: null,
      hostStation: null,
      OptMeinFutureComm: null,
      serverError: '',
      missedAdditionalDetails: false,
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(950);
    },
    isUs() {
      return this.country === 'United States';
    },
  },
  watch: {
    country(newCountry) {
      switch (newCountry) {
        case 'United States':
          this.statesProvinceList = States;
          break;
        case 'Canada':
          this.statesProvinceList = Provinces;
          break;
        default:
          this.statesProvinceList = [];
      }
      this.state = '';
    },
  },
  methods: {
    async validate(e) {
      e.preventDefault();
      // Additional Details must be enforced as required manually to avoid making each checkbox individually required
      if (this.additionalDetails.length === 0) {
        this.missedAdditionalDetails = true;
        this.$nextTick(() => {
          document.getElementById('prompt-for-details').scrollIntoView();
        });
        return;
      }
      this.$refs.submitButton.disabled = true;
      this.serverError = '';
      try {
        const params = {
          site: 'ELAM',
          recaptchaToken: await this.recaptcha(),
          body: {
            SuppliedFirstName: this.firstName,
            SuppliedLastName: this.lastName,
            SuppliedEmail: this.email,
            SuppliedCompanyName: this.company,
            SuppliedPhone: this.phone,
            Country: this.country,
            Zipcode: this.zipCode,
            City: this.city,
            Street: this.street,
            State: this.state,
            InterestType: this.interestType,
            UseCase: this.useCase,
            Notes: this.notes,
            CompanyRole: this.companyRole,
            NeviInterest: this.neviInterest,
            BudgetApproved: this.budgetApproved,
            PropertyRelationship: this.propertyRelationship,
            AdditionalDetails: this.additionalDetails,
            DesiredChargerCount: this.desiredChargerCount,
            NumberOfProperties: this.numberProperties,
            OptMeinFutureComm: this.OptMeinFutureComm,
            utm_medium: this.$route.query.utm_medium,
            utm_source: this.$route.query.utm_source,
          },
        };
        this.contactusPut(params);
      } catch (error) {
        console.error(error);
        this.$refs.submitButton.disabled = false;
      }
    },

    contactusPut(params) {
      const loader = this.$loading.show();
      if (this.$route.query.source === 'own-a-network') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'own_a_network_form_submit',
          referrer: 'https://www.electrifyamerica.com/',
          'referrer path': 'https://www.electrifyamerica.com/business-solutions/',
        });
      } else if (this.$route.query.source === 'fleet-charging') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'fleet_charging_solutions_form_submit',
          referrer: 'https://www.electrifyamerica.com/',
          'referrer path': 'https://www.electrifyamerica.com/business-solutions/',
        });
      }
      this.$store.commit('setEmail', params.body.SuppliedEmail);
      this.$store
        .dispatch('putContactUs', params)
        .then((res) => {
          if (res.status === 'success') {
            this.$router.push({ name: 'email-verification-contact-us-en' });
          } else {
            this.serverError = res.errorMsg;
          }
          loader.hide();
          this.$refs.submitButton.disabled = false;
        })
        .catch((error) => {
          loader.hide();
          console.error(error);
          this.$refs.submitButton.disabled = false;
        });
    },
    async recaptcha() {
      try {
        // Wait for recaptcha loading
        await this.$recaptchaLoaded();
        // Execute reCAPTCHA with action "login" and return the token
        return await this.$recaptcha('login');
      } catch (e) {
        throw new Error('recaptcha login failed');
      }
    },
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
    /* eslint-disable */
    if (!window.PRERENDER_INJECTED) {
      console.log('applesauce');
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-KXKDRHT');
    }

    if (this.$route.query.source === 'own-a-network') {
      this.useCase = 'Own a Public Fast Charging Network';
    }
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
};
</script>

<style lang="scss">
.contact-us {
  padding-top: 0 !important;
  .banner {
    width: 100vw;
    display: block;
    margin-bottom: 50px;
  }
  a {
    font-weight: 500;
  }
  p {
    font-size: 18px;

    span {
      font-size: 13px;
    }
  }

  position: relative;
  .header {
    text-align: initial;
    margin: 0 0 16px 0 !important;
    font-weight: bold;
    letter-spacing: 0.06px;
    line-height: 55px !important;
    font-size: 40px !important;
  }

  .email-link {
    text-decoration: underline;
  }

  .detail {
    font-size: 16px;
    color: #000000;
    font-weight: normal;
    letter-spacing: 0.03px;
  }

  .bg {
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .container {
    padding: 0 20px !important;
    margin: 0 auto 0 auto;
    max-width: 1200px !important;
    height: 100%;
    @media only screen and (min-width: 750px) {
      padding: 0 40px !important;
    }
  }

  form {
    max-width: 850px;
    width: 100%;
    margin-left: 100px;

    .form-question {
      color: #000000;
      font-weight: normal;
      font-size: 16px;
      span {
        font-size: 16px;
      }
    }

    .required {
      color: red;
    }

    .input-field {
      margin-bottom: 20px;
      position: relative;
    }

    select {
      background: url('../../assets/images/ContactUs/dropdown_arrow.png');
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      background-repeat: no-repeat;
      background-size: 15px;
      margin-top: 2px;
      background-position: right 15px center;
      border: 1px solid #011352;
      border-radius: 8px;
      padding: 0 8px;
      height: 45px;
      color: #011352;

      span {
        color: red !important;
      }
    }

    label {
      font-size: 16px;
      color: #000000;
      font-weight: normal;
      letter-spacing: 0.03px;
    }

    ::placeholder {
      color: #011352;
      font-family: 'Ubuntu', Arial, sans-serif;
    }

    textarea {
      margin-top: 40px;
      border: 1px solid #011352;
      height: 120px;
      padding: 11px;
    }

    input[type='text'] {
      -webkit-appearance: none !important;
      border-radius: 0 !important;
    }

    input {
      margin-bottom: 10px !important;
      margin-bottom: 8px;
      -webkit-box-shadow: inset 0 -1px 0 0 #011352;
      box-shadow: inset 0 -1px 0 0 #011352;

      &:focus {
        outline: none;
        -webkit-box-shadow: inset 0 -1px 0 0 #bfc4d4;
        box-shadow: inset 0 -1px 0 0 #bfc4d4;
      }
    }

    input,
    textarea,
    select {
      transition: 500ms ease;
      box-sizing: border-box;
      max-width: 600px;
    }

    textarea,
    select {
      outline: none;
      &:focus {
        border: 1px solid #bfc4d4;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    input[type='submit'] {
      width: 150px;
      padding: 22px 30px;
    }

    .disclaimer {
      font-size: 12px;
      line-height: 1.2 !important;
    }
  }

  .checkbox {
    label {
      display: flex;
    }
    p {
      flex: 1;
      margin: 0 !important;
      line-height: 1 !important;
    }
    input {
      width: fit-content;
      margin-bottom: 8px;
      -webkit-box-shadow: none;
      box-shadow: none;

      &:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
    span {
      margin-top: 8px;
      display: block;
      font-size: 18px;
    }

    .link {
      cursor: pointer;
      text-decoration: underline !important;
    }

    a {
      text-decoration: underline;
    }
  }
}

.contact-us.mobile {
  padding-top: 0px !important;
  form {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }

  input,
  textarea,
  select {
    max-width: 100%;
  }

  .checkbox input {
    width: 25px;
    height: 25px;
  }
}
.residential-note {
  padding-left: 5px;
  color: #0b0f4d;
}

.prompt-for-details {
  color: black;
  font-weight: normal;
}
.prompt-hidden {
  display: none;
}
</style>
